import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { getStorageData ,setStorageData} from "../../../framework/src/Utilities";

interface ApiCallData {
  setApiCallId: string;
  requestHeaders: Record<string, string>;
  endPoint: string;
  apiMethod: string;
  reqBody?: Record<string, object> | FormData; // Union type for JSON object or FormData
}
interface UploadedImage {
  file: File;
  name: string;
  size: number;
}

export enum ALERT_STATUS {
    IDLE = 'idle',
    SUCCESS='success',
    ERROR='error'
}

type CompressFileResponse = {
  data?: {
    id: string;
    type: string;
    attributes: {
      image_url: string;
    };
  };
  errors: string;
};




// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  downloadImages: boolean;
  selectedImages: UploadedImage[];
  compressedFiles: URL | string;
  isCompressedFile:ALERT_STATUS;
  isFileDownloaded:ALERT_STATUS;
  disableBtn:boolean;
  messageResposnse:string;
  lossyCompression:boolean;
  isLossyCompression:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FilecompressionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  uploadFileWebApiCallId: string = "";
  loginWebApiCallId: string = "";
  inputRef = React.createRef<HTMLInputElement>();
    // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      downloadImages:false,
      selectedImages:[],
      compressedFiles:"",
      isCompressedFile:ALERT_STATUS.IDLE,
      isFileDownloaded:ALERT_STATUS.IDLE,
      disableBtn:false,
      messageResposnse:"Please select a file before compress",
      lossyCompression:false,
      isLossyCompression:false
      // Customizable Area End
    };
    
    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    
    // Customizable Area Start
    this.handleAllAPIResponse(message)
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.loginUserForTokenApi();
  }
  
  handleAllAPIResponse = (message: Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );  
      if (apiRequestCallId === this.uploadFileWebApiCallId) {
        this.handelUploadFileCompressionResponse(responseJson)
      } 
      else if (apiRequestCallId === this.loginWebApiCallId) {
        if (responseJson.meta) {
          setStorageData("token", responseJson.meta.token)
      }}
    } 
  }

  closeCompressAlert =()=>{
    this.setState({isCompressedFile:ALERT_STATUS.IDLE})
  }  
  
  closeDownloadAlert = ()=>{
    this.setState({isCompressedFile:ALERT_STATUS.IDLE,isFileDownloaded:ALERT_STATUS.IDLE})
  }
  
  

  handelUploadFileCompressionResponse = (responseJson:CompressFileResponse)=>{
    if(responseJson.data){
      this.setState({ compressedFiles: responseJson.data.attributes.image_url, downloadImages:true,isCompressedFile:ALERT_STATUS.SUCCESS })
      this.changeCompressFileUploadStatus()
    }
    else{
      this.setState({isCompressedFile:ALERT_STATUS.ERROR,messageResposnse:responseJson?.errors||"Something went wrong"})
      setTimeout(()=>{
        this.setState({isCompressedFile:ALERT_STATUS.IDLE})
      },5000)
    }
this.setState({disableBtn:false})
  }

  changeCompressFileUploadStatus = ()=>{
    setTimeout(()=>{
     this.setState({isCompressedFile:ALERT_STATUS.IDLE,isFileDownloaded:ALERT_STATUS.IDLE}) 
    },5000)
  }

  downloadUploadedFiles = () => {
    if(this.state.downloadImages){
      const compressedFilesUrl = this.state.compressedFiles;
      // We can not use runEgine to make a API call for compressedFilesUrl (external URL). 
      const fileDownloadRequest = new XMLHttpRequest();
      fileDownloadRequest.open('GET', compressedFilesUrl.toString(), true);
      fileDownloadRequest.responseType = 'blob';
      fileDownloadRequest.onload = () => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(fileDownloadRequest.response);
        link.download = this.state.selectedImages[0].name;
        link.click();
        this.setState({isCompressedFile:ALERT_STATUS.IDLE, isFileDownloaded:ALERT_STATUS.SUCCESS})
        this.changeCompressFileUploadStatus()
      };
      fileDownloadRequest.send(); 
    }else{
      this.setState({isFileDownloaded:ALERT_STATUS.ERROR})
      setTimeout(() => {
        this.setState({isFileDownloaded:ALERT_STATUS.IDLE})
      }, 5000);
    }
  };

  clearSelectedFiles = (inputRef: React.RefObject<HTMLInputElement | null>) => {
    this.setState({ selectedImages: [],compressedFiles:"",downloadImages:false,lossyCompression:false});
    if (inputRef.current) inputRef.current.value = '';
  };

  compressUploadedFile = async () => {
    if(this.state.selectedImages.length){
      this.setState({disableBtn:true});
        const { exampleAPiMethod, FilecompressApiEndPoint } = configJSON;
        const authToken = await getStorageData("token");
        const requestHeaders = { "token": authToken };
        
        const formData = new FormData();
        
        formData.append('image', this.state.selectedImages[0].file);
        formData.append('lossy_compression',this.state.isLossyCompression+'')

        this.apiCall({
          setApiCallId: "uploadFileWebApiCallId",
          requestHeaders,
          apiMethod: exampleAPiMethod,
          endPoint: FilecompressApiEndPoint,
          reqBody: formData
        });
    }else{
      this.setState({isCompressedFile:ALERT_STATUS.ERROR})
      setTimeout(()=>{
        this.setState({isCompressedFile:ALERT_STATUS.IDLE})
      },5000)
    }
  };

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, inputRef: React.RefObject<HTMLInputElement | null>) => {
    const filesData = event.target.files;
    if (!filesData) return;
    const allowedFormats = ['.jpg', '.jpeg', '.gif', '.png'];
    
    const selectedFilesArray: File[] = Array.from(filesData);

    if (selectedFilesArray.length > 0) {
      if (selectedFilesArray.every(file => file.name && typeof file.name === 'string' && allowedFormats.includes("." + file.name.split('.').pop()!.toLowerCase()))) {

        const uploadedImages: UploadedImage[] = selectedFilesArray.map(file => ({ name: file.name, file: file, size: file.size }));

        if (selectedFilesArray.every(file => (file.size / 1024) <= 10000)) {

          this.setState({ selectedImages: uploadedImages });
          this.handleIsLossyCompression(selectedFilesArray)

        } else {
          this.setState({ selectedImages: [], lossyCompression: false });
          this.showAlert(
            "File Error",
            "File size should be less than 10MB"
          );
        }
      } else {
        if (inputRef.current) { inputRef.current.value = '' }
        this.setState({ selectedImages: [], lossyCompression: false })
        this.showAlert(
          "Invalid file formate",
          "Please select files with .jpg, .gif, or .png extensions."
        );
      }
    }
  }

  handleIsLossyCompression=(selectedFilesArray:File[])=>{
    const lossyCompressionFormatcheck = ['.jpg', '.jpeg', '.png'];
    
    if (selectedFilesArray.every(file => file.name && typeof file.name === 'string' && lossyCompressionFormatcheck.includes("." + file.name.split('.').pop()!.toLowerCase()))) {
      this.setState({ lossyCompression: true })
    } else {
      this.setState({ lossyCompression: false,isLossyCompression:false })
    }
  }

  loginUserForTokenApi = async () => {
    const { validationApiContentType, exampleAPiMethod, LoginApiEndpoint, loginPwd } = configJSON;
    const authToken = await getStorageData("token");
    const requestHeaders = {
      "Content-Type": validationApiContentType,
      "token": authToken
    };
    const bodyData = {
      data: {
        attributes: {
          "user_name": "hope1234R",
          "password": loginPwd
        },
        "type": "password"
      }
    };
    this.apiCall({
      setApiCallId: "LoginWebApiCallId",
      requestHeaders,
      apiMethod: exampleAPiMethod,
      endPoint: LoginApiEndpoint,
      reqBody: bodyData
    });
  };
  apiCall = async (requestData: ApiCallData) => {
    const { setApiCallId, requestHeaders, endPoint, apiMethod, reqBody } = requestData;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

   
    switch(setApiCallId){
       case "LoginWebApiCallId" : {
        this.loginWebApiCallId = requestMessage.messageId;
        break;
       } 
       case "uploadFileWebApiCallId" :{
        this.uploadFileWebApiCallId = requestMessage.messageId;
        break;
       }
    }

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(requestHeaders));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), apiMethod);
    if (reqBody) {
      if (reqBody instanceof FormData) {
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), reqBody);
      } else {
        const requestBody = typeof reqBody === "string" ? reqBody : JSON.stringify(reqBody);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), requestBody);
      }
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handlelossyCompression=(event: React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({ isLossyCompression: event.target.checked })
  }

  markBrowsFileColors=()=>{
    return this.state.selectedImages.length?'black':'transparent'
  }
  // Customizable Area End
}