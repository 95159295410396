Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "filecompression";
exports.labelBodyText = "filecompression Body";

exports.btnExampleTitle = "CLICK ME";

exports.compressApiUrl = `bx_block_filecompression/filecompressions`;
exports.compressMethod = "POST";
exports.compressContentType = "multipart/form-data";

exports.loginApiUrl = `bx_block_login/logins`;
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";
exports.testingCredUsername = "test_user";
exports.testingCredPass = "testing";
exports.FilecompressApiEndPoint = "bx_block_filecompression/filecompressions";
exports.LoginApiEndpoint = "bx_block_login/logins";
exports.loginPwd = "Test@1234";


// Customizable Area End